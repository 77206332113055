































import { Application } from '@/generated/graphql';
import { getActiveProducts, getNameFromApplication } from '@/helpers/dogHelper';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ProductsChipGroup from '../integrations/ProductsChipGroup.vue';
import { Ripple } from 'vuetify/lib/directives';
type SelectableApplication = Application & { isSelectable: boolean };

@Component({
  directives: { Ripple },
  components: { ProductsChipGroup },
  methods: { getActiveProducts, getNameFromApplication },
})
export default class ApplicationTable extends Vue {
  @Prop({ default: () => false })
  loading!: boolean;

  @Prop({ default: () => [] })
  items!: Application[];

  @Prop({ default: () => [] })
  selected!: Application[];

  @Prop({ default: () => [] })
  notSelectableIds!: string[];

  headers = [
    {
      text: this.$t('common.applicationTable.headers.applicationName'),
      align: 'start',
      value: 'name',
    },
    {
      text: this.$t('common.applicationTable.headers.applicationId'),
      align: 'start',
      value: 'id',
    },
    {
      text: this.$t('common.applicationTable.headers.hierarchy'),
      align: 'start',
      value: 'hierarchy',
    },
    {
      text: this.$tc('common.applicationTable.headers.products', 2),
      align: 'start',
      value: 'products',
    },
  ];

  get itemsProcessed(): SelectableApplication[] {
    return this.items.map(v => {
      return { ...v, isSelectable: this.notSelectableIds.indexOf(v.id) === -1 };
    });
  }
}
