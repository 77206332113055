















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({ components: {}, props: {}, methods: {} })
export default class StartCertification extends Vue {}
