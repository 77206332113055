




























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// @ts-ignore
import VueStickyDirective from '@renatodeleao/vue-sticky-directive/dist/vue-sticky-directive.esm';
import NewIntegrationTable, { NewIntegrationTableItem } from '@/components/integrations/create/NewIntegrationTable.vue';
import SearchApplicationsDialog from '@/components/common/SearchApplicationsDialog.vue';
import {
  Application,
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables,
  FetchIntegrationsByAppIdQuery,
  FetchIntegrationsByAppIdQueryVariables,
  Integration,
} from '@/generated/graphql';
import { getNameFromApplication } from '@/helpers/dogHelper';
import IntegrationDetails from '@/components/integrations/IntegrationDetails.vue';
import { Action } from 'vuex-class';
import { CoreActions } from '@/store/core/actions.core';
import { ActionMethod } from '@/helpers/typeHelpers';

@Component({
  components: { NewIntegrationTable, SearchApplicationsDialog, IntegrationDetails },
  props: {},
  directives: { sticky: VueStickyDirective },
})
export default class CreateIntegrationBase extends Vue {
  step = 0;
  showSearchDialog = false;
  newIntegrations: NewIntegrationTableItem[] = [];
  currentSelection: NewIntegrationTableItem | null = null;
  stickyOptions = { topSpacing: 128 };
  newIntegrationsValid = false;
  loading = false;
  variables: CreateIntegrationMutationVariables = {
    name: '',
    appId: '',
    os: '',
    region: '',
    clientId: '',
    c6: '',
    deviceTypeId: 0,
    dcrStreamTypeId: 0,
  };

  @Action(`core/${CoreActions.FETCH_INTEGRATION_BY_APP_ID}`)
  fetchIntegrationsByAppId!: ActionMethod<FetchIntegrationsByAppIdQueryVariables, FetchIntegrationsByAppIdQuery>;

  @Action(`core/${CoreActions.MUTATION_CREATE_INTEGRATION}`)
  createIntegration!: ActionMethod<CreateIntegrationMutationVariables, CreateIntegrationMutation>;

  mounted() {
    this.newIntegrationsValid = false;
    // @ts-ignore
    this.$gtag.pageview('/create-integration');
  }

  get hasSelectionsAndIsValid(): boolean {
    return (
      this.newIntegrations.length > 0 && this.newIntegrationsValid && this.newIntegrations.some(n => !n.integration)
    );
  }
  async onApplicationsSelected(selected: Application[]) {
    this.loading = true;
    try {
      const selection = await Promise.all(
        selected.map(async a => {
          const existing = await this.checkExistingIntegrationForAppId(a);
          const entry: NewIntegrationTableItem = {
            application: a,
            integrationName: getNameFromApplication(a),
            deviceType: null,
            dcrVideoStreamType: null,
            integration: existing,
            validations: {
              integrationAlreadyExists: existing ? true : false,
              integrationCreated: false,
              error: null,
            },
          };
          return entry;
        })
      );
      this.newIntegrations = [...this.newIntegrations, ...selection];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async checkExistingIntegrationForAppId(item: Application): Promise<Integration | null> {
    const response = await this.fetchIntegrationsByAppId({ appId: item.id });
    if (response.fetchIntegrationsByAppId.length > 0) {
      return response.fetchIntegrationsByAppId[0];
    }
    return null;
  }

  removeItemFromSelection(item: NewIntegrationTableItem) {
    const index = this.newIntegrations.indexOf(item);
    if (index > -1) {
      this.newIntegrations.splice(index, 1);
    }
  }

  async createIndividual() {
    const creatable = this.newIntegrations.filter(n => {
      return n.integration == null;
    });
    this.loading = true;
    console.log({ creatable });
    for (const n of creatable) {
      try {
        const response = await this.createIntegration({
          name: n.integrationName,
          appId: n.application.id,
          os: n.application.operatingSystem!,
          region: n.application.countryCode!,
          clientId: n.application.ddrsEntity!.clientId!,
          c6: n.application.ddrsEntity!.vcId!,
          deviceTypeId: n.deviceType?.id,
          dcrStreamTypeId: n.dcrVideoStreamType?.id,
        });

        if (response.createIntegration == null) {
          const errorMessage = this.formatErrorMessage(response.toLocaleString());
          this.setValidationError(n, errorMessage);
        }
        if (response.createIntegration?.integration) {
          const index = this.newIntegrations.findIndex(v => v.application.id === n.application.id);
          this.$set(this.newIntegrations, index, {
            ...n,
            integration: response.createIntegration?.integration,
            validations: { integrationAlreadyExists: false, error: null, integrationCreated: true },
          });
        }
        // @ts-ignore
        /* eslint-disable @typescript-eslint/camelcase */
        this.$gtag.event('create_integration', {
          event_category: 'integrations',
          event_label: 'Create Integration',
          value: response.createIntegration?.integration,
        });
      } catch (e) {
        console.error(e);
        const errorMessage = this.formatErrorMessage(e);
        this.setValidationError(n, errorMessage);
      }
    }
    this.loading = false;
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  formatErrorMessage(error: any): any {
    const matchedKeys = Object.keys(this.variables).filter(key => error.toLocaleString().includes(key));
    const errorMessage =
      matchedKeys.length == 1 ? this.$t('createIntegration.errorMessage', { keyword: matchedKeys[0] }) : error;
    return errorMessage;
  }

  setValidationError(integration: NewIntegrationTableItem, errorMessage: string) {
    const index = this.newIntegrations.findIndex(v => v.application.id === integration.application.id);
    this.$set(this.newIntegrations, index, {
      ...integration,
      validations: { integrationAlreadyExists: false, error: errorMessage },
    });
  }
  createAndAddToGroup() {
    console.log('createAndAddToGroup');
  }
}
