


































































import {
  Application,
  Country,
  FetchDogCountriesQueryVariables,
  FetchDogSearchQuery,
  FetchDogSearchQueryVariables,
  Os,
} from '@/generated/graphql';
import { ActionMethod } from '@/helpers/typeHelpers';
import { CoreActions } from '@/store/core/actions.core';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { getOsArray } from '@/helpers/osHelper';
import { CoreGetter } from '@/store/core/getter.core';
import ApplicationTable from '@/components/common/ApplicationTable.vue';

@Component({
  name: 'SearchApplicationsDialog',
  components: { ApplicationTable },
  methods: { getOsArray },
})
export default class SearchApplicationsDialog extends Vue {
  @Prop()
  value!: boolean;

  @Prop({ default: () => [] })
  alreadySelected!: Application[];

  searchTimeout: NodeJS.Timeout | null = null;
  filters: { search: string; os: Os | null; country: Country | null } = { search: '', os: null, country: null };
  selected: Application[] = [];
  isLoading = false;
  results: Application[] = [];

  @Action(`core/${CoreActions.FETCH_APPLICATION_SEARCH}`)
  performSearchDog!: ActionMethod<FetchDogSearchQueryVariables, FetchDogSearchQuery>;

  @Getter(`core/${CoreGetter.GET_COUNTRIES}`)
  countries!: Country[];

  @Action(`core/${CoreActions.FETCH_COUNTRIES}`)
  performFetchCountries!: ActionMethod<FetchDogCountriesQueryVariables, unknown>;

  onSelectionChange(items: Application[]) {
    this.selected = [...items];
  }

  @Watch('value')
  onShowChange() {
    if (this.value) {
      setTimeout(() => {
        // @ts-ignore
        this.$refs.search.focus();
      }, 300);
      this.selected = [];
      this.results = [];
      this.filters.search = '';
      this.filters.os = null;
      this.filters.country = null;
    }
  }

  @Watch('filters.search', { deep: true })
  async onSearchChange(to: string) {
    if (to !== null && to.length >= 3) {
      if (this.searchTimeout != null) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        await this.doSearch();
        clearTimeout(this.searchTimeout!);
        this.searchTimeout = null;
      }, 1000);
    }
  }

  async doSearch() {
    const payload: FetchDogSearchQueryVariables = {
      search: this.filters.search,
      operatingSystem: this.filters.os as Os,
      countryCode: this.filters.country?.code,
    };

    this.isLoading = true;
    const response = await this.performSearchDog(payload);
    this.results = response.fetchDogSearch;
    this.isLoading = false;
  }

  async mounted() {
    this.performFetchCountries();
  }

  onClose() {
    this.$emit('input', false);
  }

  onAdd() {
    this.$emit('input', false);
    this.$emit('applications', this.selected);
  }
}
