























import { Date } from '@/mixins/date';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import { DdrsEntity, Workflow, Integration } from '@/generated/graphql';
import { mixins } from 'vue-class-component';
import { CoreGetter } from '@/store/core/getter.core';
import { Getter } from 'vuex-class';
import { productDeprecated } from '@/helpers/integrationHelpers';

@Component({ components: {}, props: {}, methods: { getCssClassFromState, productDeprecated } })
export default class WorkflowCard extends mixins(Date) {
  @Prop({ default: () => null })
  workflow!: Workflow;

  @Prop({ default: () => null })
  ddrsEntry!: DdrsEntity | null;

  @Prop({ default: () => [] })
  properties!: string[];

  @Getter(`core/${CoreGetter.GET_SELECTED_INTEGRATION}`)
  currentIntegration!: Integration | null;

  get deprecated() {
    return productDeprecated(this.currentIntegration, this.workflow.product);
  }

  @Emit()
  click() {
    return this.workflow;
  }
}
