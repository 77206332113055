var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"value":_vm.selected,"loading":_vm.loading,"items":_vm.itemsProcessed,"disable-sort":"","headers":_vm.headers,"show-select":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"disabled":!item.isSelectable,"value":item.isSelectable ? isSelected : true},on:{"input":function($event){return select($event)}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-cy":"test-app-name"}},[_vm._v(_vm._s(_vm.getNameFromApplication(item)))])]}},{key:"item.hierarchy",fn:function(ref){
var item = ref.item;
return [(item.ddrsEntity)?_c('span',[_vm._v(_vm._s(item.ddrsEntity.parent)+" "),_c('v-icon',[_vm._v("arrow_right")]),_vm._v(" "+_vm._s(item.ddrsEntity.name))],1):_vm._e()]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_c('products-chip-group',{attrs:{"app":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }