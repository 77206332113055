




























import { ROUTER } from '@/constants';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import WorkflowCard from '@/components/integrations/workflow/WorkflowCard.vue';
import IntegrationDetails from '@/components/integrations/IntegrationDetails.vue';
import StartCertificationDialog from '@/components/integrations/StartCertificationDialog.vue';
import { Action, Getter } from 'vuex-class';
import { CoreActions } from '@/store/core/actions.core';
import { ActionMethod } from 'vuex';
import { CoreGetter } from '@/store/core/getter.core';
import ProductChipGroup from '@/components/integrations/ProductsChipGroup.vue';
import {
  Application,
  Country,
  FetchDogApplicationQueryVariables,
  FetchIntegrationQueryVariables,
  FetchWorkflowsQueryVariables,
  Integration,
  Workflow,
} from '@/generated/graphql';
import { getActiveProducts } from '@/helpers/dogHelper';

@Component({
  components: {
    StartCertificationDialog,
    WorkflowCard,
    ProductChipGroup,
    IntegrationDetails,
  },
  methods: { getActiveProducts },
})
export default class WorkflowList extends Vue {
  country: Country | null = null;
  @Action(`core/${CoreActions.FETCH_SELECTED_INTEGRATION}`)
  performFetchSelectedIntegration!: ActionMethod;

  @Action(`core/${CoreActions.FETCH_APPLICATION}`)
  performFetchApplication!: ActionMethod;

  @Action(`core/${CoreActions.FETCH_WORKFLOWS}`)
  performFetchWorkflows!: ActionMethod;

  @Action(`core/${CoreActions.GET_COUNTRY_FOR_CODE}`)
  performGetCountryByCode!: ActionMethod;

  @Getter(`core/${CoreGetter.GET_SELECTED_INTEGRATION}`)
  integration!: Integration | null;

  @Getter(`core/${CoreGetter.GET_APPLICATION}`)
  application!: Application | null;

  @Getter(`core/${CoreGetter.GET_SELECTED_INTEGRATION_WORKFLOWS}`)
  workflows!: Workflow[] | null;

  get workflowsSortedAndCombined(): Workflow[] {
    if (this.workflows) {
      return this.workflows
        .sort((a: Workflow, b: Workflow) => {
          return a.createdAt - b.createdAt;
        })
        .reduce((acc: Workflow[], wf: Workflow) => {
          const notPresent = !acc.some(w => w.product === wf.product);
          if (notPresent) {
            acc.push(wf);
          }
          return acc;
        }, [])
        .reverse();
    }
    return [];
  }

  get showStartCertification(): boolean {
    if (this.application && this.workflowsSortedAndCombined) {
      return getActiveProducts(this.application)?.length > this.workflowsSortedAndCombined.length;
    }
    return false;
  }

  startCertification() {
    this.getIntegration(true);
  }

  mounted() {
    this.getIntegration(true);
    //@ts-ignore
    this.$gtag.pageview('/integration-overview');
  }

  beforeDestroy() {
    this.$store.commit('core/CLEAR');
  }

  async getIntegration(force = false) {
    const iId = this.$route.params.integrationId;
    const integrationId = parseInt(iId);
    if (!this.integration || this.integration.id !== integrationId || force) {
      const workflowPayload: FetchWorkflowsQueryVariables = { integrationId };
      this.performFetchWorkflows(workflowPayload);
      const payload: FetchIntegrationQueryVariables = { integrationId };
      await this.performFetchSelectedIntegration(payload);
      if (this.integration) {
        const applicationPayload: FetchDogApplicationQueryVariables = {
          id: this.integration!.appId!,
        };
        this.performFetchApplication(applicationPayload);
      }
      this.findCountryByCode(this.integration!.region!);
    }
  }

  showWorkflow(workflow: Workflow) {
    const isCreated = this.$router.currentRoute.query.isCreated;
    const query = isCreated == 'true' ? { isCreated: 'true' } : {};
    if (workflow?.product) {
      this.$router
        .push({
          name: ROUTER.WORKFLOW_DETAILS,
          params: {
            integrationId: this.integration!.id.toString(),
            product: workflow!.product.toString(),
          },
          query: query,
        })
        .catch(err => console.error(err));
    }
  }

  async findCountryByCode(country: string) {
    const c = await this.performGetCountryByCode(country);
    this.country = c;
  }
}
