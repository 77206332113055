
























































































































import { EVENTS, ROUTER } from '@/constants';
import { Application, FetchTagsQuery, FetchTagsQueryVariables, Integration, Tag, TagKind } from '@/generated/graphql';
import { getActiveProducts } from '@/helpers/dogHelper';
import { ActionMethod, VForm } from '@/helpers/typeHelpers';
import { CoreActions } from '@/store/core/actions.core';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { EventBus } from '@/mixins/eventBus';

export type NewIntegrationTableItem = {
  application: Application;
  integrationName: string;
  deviceType: Tag | null;
  dcrVideoStreamType: Tag | null;
  integration: Integration | null;
  validations: {
    integrationAlreadyExists: boolean;
    integrationCreated: boolean;
    error: string | null;
  };
};

@Component({
  methods: { getActiveProducts },
})
export default class NewIntegrationTable extends Vue {
  @Prop({ default: () => [] })
  items!: NewIntegrationTableItem[];
  @Prop({ default: () => false })
  showHoverIndicator!: boolean;
  deviceTypes: Tag[] = [];
  dcrContentTypes: Tag[] = [];
  currentHoveredApplication: Application | null = null;
  formValid = false;
  loading = false;

  hasDcrVideo(item: NewIntegrationTableItem) {
    return getActiveProducts(item.application).indexOf('dcr_video') !== -1;
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  routerLinkForIntegration(integration: Integration) {
    return { name: ROUTER.WORKFLOW_LIST, params: { integrationId: integration.id } };
  }

  dcrStreamType(integration: Integration): string {
    const found = integration?.tags?.find(t => t.kind === TagKind.DcrStreamType);
    if (found) {
      return this.$t(`dcrStreamTypes.${found.name}`).toString();
    }
    return '';
  }

  async validate() {
    await this.$nextTick();
    this.form.validate();
    await this.$nextTick();
    this.$emit('valid', this.formValid);
    this.items.forEach(item => {
      if (item.validations.error) {
        EventBus.$emit(EVENTS.GLOBAL_ALERT, { message: this.$t('createIntegration.createIntegrationError') });
      }
    });
  }

  @Watch('items', { deep: true })
  onItemsUpdate() {
    this.validate();
  }

  headers = [
    {
      text: '',
      value: 'menu',
    },
    {
      text: this.$t('createIntegration.applicationTable.headers.integrationName'),
      align: 'start',
      value: 'integrationName',
    },
    {
      text: this.$tc('createIntegration.applicationTable.headers.deviceType', 1),
      align: 'start',
      value: 'deviceType',
    },
    {
      text: this.$t('createIntegration.applicationTable.headers.dcrStreamType'),
      align: 'start',
      value: 'dcrVideoStreamType',
    },
  ];

  @Action(`core/${CoreActions.FETCH_TAGS}`)
  performFetchTags!: ActionMethod<FetchTagsQueryVariables, FetchTagsQuery>;

  async mounted() {
    const indicatorSpacer = {
      text: '',
      align: 'start',
      value: '',
    };
    if (this.showHoverIndicator) {
      this.headers.push(indicatorSpacer);
    }
    this.deviceTypes = (await this.performFetchTags({ kind: TagKind.DeviceType })).fetchTags;
    this.dcrContentTypes = await (await this.performFetchTags({ kind: TagKind.DcrStreamType })).fetchTags;
  }
  currentHover(item: NewIntegrationTableItem) {
    this.currentHoveredApplication = item.application;
    this.$emit('currentHover', item);
  }
}
