







































import { Application, Integration, StartCertificationMutationVariables, User, Workflow } from '@/generated/graphql';
import Vue from 'vue';
import { getActiveProducts } from '@/helpers/dogHelper';
import StartCertificationCard from '@/components/integrations/StartCertificationCard.vue';
import { Component, Prop } from 'vue-property-decorator';
import { VForm } from '@/helpers/typeHelpers';
import { Action, Getter } from 'vuex-class';
import { CoreActions } from '@/store/core/actions.core';
import { ActionMethod } from 'vuex';
import { UserGetter } from '@/store/user/getters.user';
@Component({ components: { StartCertificationCard }, methods: { getActiveProducts } })
export default class StartCertificationDialog extends Vue {
  product = '';
  formValid = false;
  show = false;

  loading = false;

  @Prop({ default: () => null })
  workflows!: Workflow[] | null;

  @Prop({ default: () => null })
  application!: Application | null;

  @Prop({ default: () => null })
  integration!: Integration | null;

  @Getter(`user/${UserGetter.GET_STANDARD_USER}`)
  users!: User[];

  @Getter(`user/${UserGetter.IS_LOADING}`)
  userLoading!: boolean;

  @Action(`core/${CoreActions.MUTATION_START_CERTIFICATION}`)
  performStartCertification!: ActionMethod;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  get startCertificationProducts() {
    return getActiveProducts(this.app)
      .filter(v => !this.workflowProducts.includes(v))
      .map(p => {
        return {
          name: this.$t(`products.${p}`),
          value: p,
        };
      });
  }

  get workflowProducts() {
    const products = [];
    if (this.workflows) {
      for (const w of this.workflows) {
        products.push(w.product);
      }
    }
    return products;
  }

  get app() {
    return this.application;
  }

  async startCertification() {
    this.form.validate();
    if (this.formValid) {
      const payload: StartCertificationMutationVariables = {
        integrationId: this.integration!.id,
        product: this.product,
        assignee: 'foo.bar@nielsen.com',
        data: {},
      };
      this.loading = true;
      await this.performStartCertification(payload);
      this.loading = false;
      this.$emit('start-certification', { product: this.product });
      this.show = false;
      // @ts-ignore
      /* eslint-disable @typescript-eslint/camelcase */
      this.$gtag.event('start_certification', {
        event_category: 'workflow',
        event_label: 'start certification for integration product',
        value: `${this.integration!.id}_${this.product}`,
      });
    }
  }
}
